import React from 'react';
import FarmConnectLogo from '!svg-react-loader?name=FarmConnectLogo!assets/svg/logo-farm-connect-green-and-white-icon-only.svg';
import { useTranslation } from 'react-i18next';
import { useThemeSetting } from '@utils/settings';
const LoadingScreen = () => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: `loading-container ${useThemeSetting()}-theme` },
        React.createElement("div", { className: 'logo-container' },
            React.createElement("div", { className: 'logo-spinner' },
                React.createElement(FarmConnectLogo, null)),
            React.createElement("h1", null, t('main.pageIsLoading')))));
};
export default LoadingScreen;
