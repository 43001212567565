import { store } from '@state/store';
import Cookies from 'universal-cookie';
import { useIsMechanicAccount } from './users';
export const defaultColorSetting = '008072';
export const defaultThemeSetting = 'dark';
export const defaultLanguageSetting = 'en';
export const defaultSettingHouseUptimeSetting = 20;
export const defaultDateFormatSetting = 'EEEE d MMMM';
export const defaultTimeFormatSetting = 'H:mm';
export const useColorSetting = () => {
    const cookies = new Cookies();
    const isMechanicAccount = useIsMechanicAccount();
    const auth = store.getState().auth;
    const userSettings = store.getState().users.user.settings;
    const colorCookie = cookies.get('color');
    const colorApi = userSettings && userSettings.find((setting) => setting.name === 'color')?.value;
    const color = isMechanicAccount ? 'F86F00' : auth?.success && colorApi && colorApi !== 'undefined' ? colorApi : colorCookie ? colorCookie : defaultColorSetting;
    return color;
};
export const useThemeSetting = () => {
    const cookies = new Cookies();
    const auth = store.getState().auth;
    const userSettings = store.getState().users.user.settings;
    const themeCookie = cookies.get('theme');
    const themeApi = userSettings && userSettings.find((setting) => setting.name === 'theme')?.value;
    const theme = auth?.success && themeApi && themeApi !== 'undefined' ? themeApi : themeCookie ? themeCookie : defaultThemeSetting;
    return theme;
};
export const useLanguageSetting = () => {
    const cookies = new Cookies();
    const auth = store.getState().auth;
    const userSettings = store.getState().users.user.settings;
    const languageCookie = cookies.get('language');
    const languageApi = userSettings && userSettings.find((setting) => setting.name === 'language')?.value;
    const language = auth?.success && languageApi && languageApi !== 'undefined' ? languageApi : languageCookie ? languageCookie : defaultLanguageSetting;
    return language;
};
export const useSettingHouseUptimeSetting = () => {
    const cookies = new Cookies();
    const auth = store.getState().auth;
    const userSettings = store.getState().users.user.settings;
    const settingHouseUptimeCookie = cookies.get('settingHouseUptime');
    const settingHouseUptimeApi = userSettings && userSettings.find((setting) => setting.name === 'settingHouseUptime')?.value;
    const settingHouseUptime = auth?.success && settingHouseUptimeApi && settingHouseUptimeApi !== 'undefined' ? settingHouseUptimeApi : settingHouseUptimeCookie ? settingHouseUptimeCookie : defaultSettingHouseUptimeSetting;
    return settingHouseUptime;
};
export const useDateFormatSetting = () => {
    const cookies = new Cookies();
    const auth = store.getState().auth;
    const userSettings = store.getState().users.user.settings;
    const dateFormatCookie = cookies.get('dateFormat');
    const dateFormatApi = userSettings && userSettings.find((setting) => setting.name === 'dateFormat')?.value;
    const dateFormat = auth?.success && dateFormatApi && dateFormatApi !== 'undefined' ? dateFormatApi : dateFormatCookie ? dateFormatCookie : defaultDateFormatSetting;
    return dateFormat;
};
export const useTimeFormatSetting = () => {
    const cookies = new Cookies();
    const auth = store.getState().auth;
    const userSettings = store.getState().users.user.settings;
    const timeFormatCookie = cookies.get('timeFormat');
    const timeFormatApi = userSettings && userSettings.find((setting) => setting.name === 'timeFormat')?.value;
    const timeFormat = auth?.success && timeFormatApi && timeFormatApi !== 'undefined' ? timeFormatApi : timeFormatCookie ? timeFormatCookie : defaultTimeFormatSetting;
    return timeFormat;
};
