import React, { Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@state/store';
import i18n from '../i18n';
import { Authenticator } from '@aws-amplify/ui-react';
import '@utils/amplify';
import { ToastContainer } from 'react-toastify';
import { safeLazy } from 'react-safe-lazy';
import LoadingScreen from '@layout/LoadingScreen';
const FarmConnect = safeLazy(() => import('./Container'));
const MainApiCalls = safeLazy(() => import('@layout/MainApiCalls'));
const UserCookies = safeLazy(() => import('@layout/UserCookies'));
const UserSession = safeLazy(() => import('@layout/UserSession'));
const Application = () => {
    return (React.createElement(Authenticator.Provider, null,
        React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(Provider, { store: store },
                React.createElement(PersistGate, { loading: null, persistor: persistor },
                    React.createElement(Suspense, { fallback: React.createElement(LoadingScreen, null) },
                        React.createElement(Router, null,
                            React.createElement(ToastContainer, { draggable: true, newestOnTop: true, pauseOnHover: true, pauseOnFocusLoss: true, position: 'bottom-right' }),
                            React.createElement(MainApiCalls, null),
                            React.createElement(UserCookies, null),
                            React.createElement(UserSession, null),
                            React.createElement(FarmConnect, null))))))));
};
export default Application;
