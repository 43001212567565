import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    user: {}
};
const cognito = createSlice({
    name: 'cognito',
    initialState,
    reducers: {
        storeUserSuccess(state, action) {
            state.user = action.payload;
        },
        storeTempUserSuccess(state, action) {
            state.user = action.payload;
        }
    }
});
export const { storeUserSuccess, storeTempUserSuccess } = cognito.actions;
export default cognito.reducer;
