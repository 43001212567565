import { store } from '@state/store';
import { UserRoles } from 'constants/users';
export const getUserRoleByValue = (value) => {
    return UserRoles.find((role) => role.value === value || role.withoutEdit === value);
};
export const isUserAllowedToEdit = (value) => {
    const role = UserRoles.find((role) => role.value === value || role.withoutEdit === value);
    return role.value === value;
};
export const getRoleValueByRoleKeyAndEdit = (roleKey, edit) => {
    const role = UserRoles.find((role) => role.key === roleKey);
    return edit ? role.value : role.withoutEdit;
};
export const useGetUser = () => {
    const cognito = store.getState().cognito;
    const user = cognito?.user;
    return user;
};
export const useGetUserId = () => {
    const cognito = store.getState().cognito;
    const userId = cognito?.user?.userId;
    return userId;
};
export const useIsCustomerAccount = () => {
    const cognito = store.getState().cognito;
    const isCustomerAccount = cognito?.user?.isCustomerAccount;
    return isCustomerAccount;
};
export const useIsServiceAccount = () => {
    const cognito = store.getState().cognito;
    const isServiceAccount = cognito?.user?.isServiceAccount;
    return isServiceAccount;
};
export const useIsDealerAccount = () => {
    const cognito = store.getState().cognito;
    const isDealerAccount = cognito?.user?.isDealerAccount;
    return isDealerAccount;
};
export const useIsMechanicAccount = () => {
    const cognito = store.getState().cognito;
    const isMechanicAccount = cognito?.user?.isMechanicAccount;
    return isMechanicAccount;
};
export const useIsRootAccount = () => {
    const cognito = store.getState().cognito;
    const isRootAccount = cognito?.user?.isRootAccount;
    return isRootAccount;
};
export const useGetUserGroups = () => {
    const cognito = store.getState().cognito;
    const userGroups = cognito?.user?.userGroups;
    return userGroups;
};
