import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    dashboard: [],
    tabs: [],
    widgets: [],
    wizard: []
};
const widgets = createSlice({
    name: 'widgets',
    initialState,
    reducers: {
        storeTabsSuccess(state, action) {
            state.tabs = action.payload;
        },
        storeNewTabSuccess(state, action) {
            const oldArray = state && state.tabs;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.tabs = newArray;
        },
        storeWidgetsSuccess(state, action) {
            state.widgets = action.payload;
        },
        storeNewWidgetSuccess(state, action) {
            const oldArray = state && state.widgets;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.widgets = newArray;
        },
        storeWidgetsWizardSuccess(state, action) {
            const oldArray = state && state.wizard;
            const newArray = oldArray && oldArray.concat(action.payload);
            state.wizard = newArray;
        },
        clearWidgetsWizardSuccess(state) {
            state.wizard = [];
        },
        storeDashboardWidgetsSuccess(state, action) {
            state.dashboard = action.payload;
        },
    }
});
export const { storeTabsSuccess, storeNewTabSuccess, storeWidgetsSuccess, storeNewWidgetSuccess, storeWidgetsWizardSuccess, clearWidgetsWizardSuccess, storeDashboardWidgetsSuccess } = widgets.actions;
export default widgets.reducer;
