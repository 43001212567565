import api from '@state/api';
const endPoint = `${process.env.USER_API}/userapi/v1`;
export const getUser = async (userId) => {
    const { data } = await api.get(`${endPoint}/users/${userId}`);
    return data;
};
export const getUserSignupComplete = async (userId) => {
    const { data } = await api.get(`${endPoint}/users/${userId}/signup-complete`);
    return data;
};
export const updateUser = async (customerId, user) => {
    const { data } = await api.post(`${endPoint}/users/${customerId}`, user);
    return data;
};
export const updateCurrentUser = async (userId, user) => {
    const { data } = await api.put(`${endPoint}/users/${userId}`, user);
    return data;
};
export const deleteUser = async (customerId, user) => {
    const { data } = await api.delete(`${endPoint}/users/${customerId}`, {
        data: user
    });
    return data;
};
export const resendInviteMail = async (userId) => {
    const { data } = await api.post(`${endPoint}/users/${userId}/resendinvite`);
    return data;
};
