import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    success: false
};
const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess(state) {
            state.success = true;
        },
        logoutSuccess(state) {
            state.success = false;
        }
    }
});
export const { loginSuccess, logoutSuccess } = auth.actions;
export default auth.reducer;
