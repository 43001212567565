import api from '@state/api';
const userEndPoint = `${process.env.USER_API}/userapi/v1`;
const rootEndPoint = `${process.env.ROOT_API}/config.svc`;
export const getLocations = async (userId) => {
    const { data } = await api.get(`${userEndPoint}/gateways/${userId}`);
    return data;
};
export const getLocationsByMechanicId = async (mechanicId) => {
    const { data } = await api.get(`${userEndPoint}/gateways/${mechanicId}`);
    return data;
};
export const getLocationStatus = async (locationId) => {
    const { data } = await api.get(`${rootEndPoint}/GetGatewaysState?gids=${locationId}`);
    return data;
};
export const AddLocationStep1 = async (customerId, location) => {
    const { data } = await api.post(`${userEndPoint}/gateways/couple/${customerId}`, location);
    return data;
};
export const getDevicesByLocationId = async (locationId) => {
    const { data } = await api.get(`${userEndPoint}/gateways/${locationId}`);
    return data;
};
