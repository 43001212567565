import api from '@state/api';
import { store } from '@state/store';
const workerEndPoint = `${process.env.WORKER_API}/workerapi/v1`;
const userEndPoint = `${process.env.USER_API}/userapi/v1`;
export const getMetrics = async (companyId) => {
    const { data } = await api.get(`${workerEndPoint}/data/metrics/${companyId}`);
    return data;
};
export const getProducts = async () => {
    const { data } = await api.get(`${userEndPoint}/products`);
    return data;
};
export const getSubscription = async (customerId) => {
    const { data } = await api.get(`${userEndPoint}/subscriptions/${customerId}`);
    return data;
};
export const updateSubscription = async (customerId, productRequests) => {
    console.log('updateSubscription');
    console.log('customerId=', customerId);
    console.log('productRequests=', productRequests);
    const response = await api.put(`${userEndPoint}/subscriptions/${customerId}`, {
        productRequests: productRequests
    });
    console.log('response=', response);
};
export const createPortalSession = async (returnUrl) => {
    const customerId = store.getState().customers?.current?.id;
    try {
        const { data } = await api.post(`${userEndPoint}/portalsessions/${customerId}`, {
            returnUrl: returnUrl,
        });
        return data;
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
export const openCustomerPortal = async (returnUrl) => {
    try {
        const session = await createPortalSession(returnUrl);
        const url = session?.url;
        if (url) {
            window.location.href = url;
        }
    }
    catch (err) {
        console.error(err);
        throw err;
    }
};
