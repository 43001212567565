import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore, } from 'redux-persist';
import rootReducer from './rootReducer';
import { setupListeners } from '@reduxjs/toolkit/query';
const persistConfig = {
    key: 'root',
    debug: false,
    storage,
    whitelist: ['auth', 'cognito', 'customers', 'alarms', 'widgets', 'users'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            ignoredPaths: ['form'],
        }
    })
});
if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        store.replaceReducer(newRootReducer);
    });
}
export const persistor = persistStore(store);
setupListeners(store.dispatch);
