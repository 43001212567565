export const UserRoles = [
    {
        key: 'service',
        text: 'Service',
        value: 100,
        withoutEdit: 200,
    },
    {
        key: 'admin',
        text: 'Admin',
        value: 300,
        withoutEdit: 300,
    },
    {
        key: 'install',
        text: 'Install',
        value: 400,
        withoutEdit: 500,
    },
    {
        key: 'user',
        text: 'User',
        value: 700,
        withoutEdit: 800,
    },
    {
        key: 'municipal',
        text: 'Municipal',
        value: 900,
        withoutEdit: 900,
    },
];
