import { useAuthenticator } from '@aws-amplify/ui-react';
import { store } from '@state/store';
import { Auth } from 'aws-amplify';
export const getIdToken = async () => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.getSignInUserSession().getIdToken().getJwtToken();
        return token;
    }
    catch (err) {
        throw new Error('getIdToken failed: ' + err);
    }
};
export const getJwtToken = () => {
    const cognito = store.getState().cognito;
    const jwtToken = cognito?.user?.jwtToken;
    return jwtToken;
};
export const getAuthHeader = async () => {
    return `Bearer ${await getIdToken()}`;
};
export const getAuthStatus = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    return authStatus;
};
export const signOutUser = () => {
    Auth.signOut()
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
};
export const checkUser = async () => {
    return await Auth.currentAuthenticatedUser()
        .then((user) => {
        user;
    })
        .catch((err) => (console.log(err)));
};
export const allowedToEdit = (userGroups) => {
    const allowed = userGroups && userGroups.includes('customer') ? true
        : false;
    return allowed;
};
